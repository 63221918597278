import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const layers = [
  {
    name: "Application",
    color: "#ef4444",
    explanation:
      "This layer is closest to the end user. It provides network services directly to applications and facilitates communication between software applications and lower layers of the network stack.",
    analogy:
      "Think of this layer as a waiter in a restaurant. The waiter interacts directly with the customers (users), takes their orders (requests), and brings them their food (data).",
    examples: "HTTP, FTP, SMTP, DNS",
    detailedInfo: {
      text: "The Application layer is responsible for identifying and establishing the availability of the intended communication partners, and determining if sufficient resources for the intended communication exist. It's the layer where user interfaces, like web browsers or email clients, interact with network services.",
      additionalExamples: "Telnet, SSH, POP3, IMAP",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#ef4444"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#ef4444" fontSize="14">
            Application Layer
          </text>
          <rect x="30" y="40" width="40" height="40" fill="#ef4444" />
          <text x="50" y="65" textAnchor="middle" fill="white" fontSize="10">
            Web
          </text>
          <rect x="80" y="40" width="40" height="40" fill="#ef4444" />
          <text x="100" y="65" textAnchor="middle" fill="white" fontSize="10">
            Email
          </text>
          <rect x="130" y="40" width="40" height="40" fill="#ef4444" />
          <text x="150" y="65" textAnchor="middle" fill="white" fontSize="10">
            FTP
          </text>
        </svg>
      ),
    },
  },
  {
    name: "Presentation",
    color: "#f97316",
    explanation:
      "This layer prepares data for the application layer. It translates between application and network formats, handling data encryption, compression, and format conversion.",
    analogy:
      "Imagine this layer as a translator. If you're reading a book in a foreign language, this layer would translate it into your language and format it for easy reading.",
    examples: "JPEG, MPEG, SSL/TLS",
    detailedInfo: {
      text: "The Presentation layer ensures that the data is in a usable format and is where data encryption occurs. It's responsible for protocol conversion, data translation, encryption, compression, and reformatting data if necessary.",
      additionalExamples: "ASCII, EBCDIC, TIFF, GIF, PICT, MIDI",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#f97316"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#f97316" fontSize="14">
            Presentation Layer
          </text>
          <rect x="30" y="40" width="40" height="40" fill="#f97316" />
          <text x="50" y="65" textAnchor="middle" fill="white" fontSize="10">
            Encrypt
          </text>
          <rect x="80" y="40" width="40" height="40" fill="#f97316" />
          <text x="100" y="65" textAnchor="middle" fill="white" fontSize="10">
            Compress
          </text>
          <rect x="130" y="40" width="40" height="40" fill="#f97316" />
          <text x="150" y="65" textAnchor="middle" fill="white" fontSize="10">
            Format
          </text>
        </svg>
      ),
    },
  },
  {
    name: "Session",
    color: "#f59e0b",
    explanation:
      "This layer establishes, manages, and terminates sessions between applications. It provides dialog control and synchronization between end-user application processes.",
    analogy:
      "Think of this as a phone call. It starts the call (session), keeps it going, and then ends it when you're done talking.",
    examples: "NetBIOS, RPC, SIP",
    detailedInfo: {
      text: "The Session layer is responsible for setting up, coordinating, and terminating conversations, exchanges, and dialogues between applications. It handles sessions and connections, offering services like authentication and reconnection if the network connection is interrupted.",
      additionalExamples: "SQL, NFS, ASP, PPTP",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#f59e0b"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#f59e0b" fontSize="14">
            Session Layer
          </text>
          <circle cx="50" cy="60" r="20" fill="#f59e0b" />
          <text x="50" y="65" textAnchor="middle" fill="white" fontSize="10">
            Start
          </text>
          <line
            x1="70"
            y1="60"
            x2="130"
            y2="60"
            stroke="#f59e0b"
            strokeWidth="2"
          />
          <circle cx="150" cy="60" r="20" fill="#f59e0b" />
          <text x="150" y="65" textAnchor="middle" fill="white" fontSize="10">
            End
          </text>
        </svg>
      ),
    },
  },
  {
    name: "Transport",
    color: "#84cc16",
    explanation:
      "This layer provides reliable data transfer services to the upper layers. It ensures data is delivered error-free, in sequence, and without losses or duplications.",
    analogy:
      "Imagine this layer as a postal service. It ensures your package (data) is properly addressed, tracked, and delivered in the correct order.",
    examples: "TCP, UDP",
    detailedInfo: {
      text: "The Transport layer is responsible for end-to-end communication, error detection and correction, and flow control. It segments data from the session layer and reassembles it into a data stream on the receiving end. TCP provides reliable, ordered delivery, while UDP offers faster, connectionless communication.",
      additionalExamples: "SCTP, DCCP, SPX",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#84cc16"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#84cc16" fontSize="14">
            Transport Layer
          </text>
          <rect x="20" y="40" width="30" height="20" fill="#84cc16" />
          <rect x="60" y="40" width="30" height="20" fill="#84cc16" />
          <rect x="100" y="40" width="30" height="20" fill="#84cc16" />
          <rect x="140" y="40" width="30" height="20" fill="#84cc16" />
          <line
            x1="20"
            y1="70"
            x2="170"
            y2="70"
            stroke="#84cc16"
            strokeWidth="2"
          />
          <text x="100" y="85" textAnchor="middle" fill="#84cc16" fontSize="10">
            Data Segmentation
          </text>
        </svg>
      ),
    },
  },
  {
    name: "Network",
    color: "#06b6d4",
    explanation:
      "This layer handles routing and forwarding of data packets between different networks. It's responsible for addressing, routing, and traffic control.",
    analogy:
      "Think of this layer as a traffic controller. It decides which road (route) your car (data packet) should take to reach its destination.",
    examples: "IP, ICMP, OSPF",
    detailedInfo: {
      text: "The Network layer provides switching and routing technologies, creating logical paths (virtual circuits) for transmitting data from node to node. It handles addressing, internetworking, error handling, congestion control and packet sequencing. IP addressing and routing occur at this layer.",
      additionalExamples: "IPsec, RIP, BGP",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#06b6d4"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#06b6d4" fontSize="14">
            Network Layer
          </text>
          <circle cx="40" cy="60" r="15" fill="#06b6d4" />
          <circle cx="100" cy="60" r="15" fill="#06b6d4" />
          <circle cx="160" cy="60" r="15" fill="#06b6d4" />
          <line
            x1="55"
            y1="60"
            x2="85"
            y2="60"
            stroke="#06b6d4"
            strokeWidth="2"
          />
          <line
            x1="115"
            y1="60"
            x2="145"
            y2="60"
            stroke="#06b6d4"
            strokeWidth="2"
          />
          <text x="100" y="85" textAnchor="middle" fill="#06b6d4" fontSize="10">
            Routing
          </text>
        </svg>
      ),
    },
  },
  {
    name: "Data Link",
    color: "#8b5cf6",
    explanation:
      "This layer provides node-to-node data transfer and handles error correction from the physical layer. It's responsible for the reliable transfer of data between two directly connected nodes.",
    analogy:
      "Imagine this as a team of workers on an assembly line. They take raw materials (bits from the physical layer), package them into boxes (frames), and ensure each box is correctly labeled and undamaged.",
    examples: "Ethernet, Wi-Fi, PPP",
    detailedInfo: {
      text: "The Data Link layer is divided into two sublayers: Logical Link Control (LLC) and Media Access Control (MAC). LLC manages error checking and frame synchronization, while MAC determines how devices in a network gain access to a medium and permission to transmit data. This layer adds a header and trailer to the data, creating a frame.",
      additionalExamples: "HDLC, FDDI, ATM",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#8b5cf6"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#8b5cf6" fontSize="14">
            Data Link Layer
          </text>
          <rect x="30" y="40" width="140" height="30" fill="#8b5cf6" />
          <rect x="40" y="45" width="120" height="20" fill="white" />
          <text x="100" y="60" textAnchor="middle" fill="#8b5cf6" fontSize="10">
            Data Frame
          </text>
          <text x="35" y="85" textAnchor="start" fill="#8b5cf6" fontSize="8">
            Header
          </text>
          <text x="165" y="85" textAnchor="end" fill="#8b5cf6" fontSize="8">
            Trailer
          </text>
        </svg>
      ),
    },
  },
  {
    name: "Physical",
    color: "#ec4899",
    explanation:
      "This layer conveys the bit stream through the network at the electrical and mechanical level. It provides the hardware means of sending and receiving data on a carrier.",
    analogy:
      "Think of this layer as the actual road system. It's the physical medium that carries the cars (data), whether it's copper wire, fiber optic cable, or radio waves for Wi-Fi.",
    examples: "Ethernet cable, Fiber optic cable, Wi-Fi radio",
    detailedInfo: {
      text: "The Physical layer defines the electrical and physical specifications of the data connection. It defines the relationship between a device and a physical medium. This includes pin layout, voltages, cable specifications, hubs, repeaters, network adapters, host bus adapters and more.",
      additionalExamples: "RS-232, DSL, ISDN",
      visual: (
        <svg viewBox="0 0 200 100" className="w-full h-32 mt-2">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="#ec4899"
            opacity="0.2"
          />
          <text x="100" y="30" textAnchor="middle" fill="#ec4899" fontSize="14">
            Physical Layer
          </text>
          <line
            x1="20"
            y1="60"
            x2="180"
            y2="60"
            stroke="#ec4899"
            strokeWidth="4"
          />
          <text x="40" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            1
          </text>
          <text x="60" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            0
          </text>
          <text x="80" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            1
          </text>
          <text x="100" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            1
          </text>
          <text x="120" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            0
          </text>
          <text x="140" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            0
          </text>
          <text x="160" y="50" textAnchor="middle" fill="#ec4899" fontSize="10">
            1
          </text>
          <text x="100" y="85" textAnchor="middle" fill="#ec4899" fontSize="10">
            Bit Stream
          </text>
        </svg>
      ),
    },
  },
].reverse(); // Reverse to display from bottom to top

const OSIModel = () => {
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [showDetailedInfo, setShowDetailedInfo] = useState(false);

  return (
    <Card sx={{ maxWidth: "4xl", margin: "auto" }}>
      <CardHeader
        title={
          <Typography variant="h4" align="center">
            Enhanced Interactive OSI Model Explorer
          </Typography>
        }
      />
      <CardContent sx={{ display: "flex" }}>
        <div style={{ width: "33%" }}>
          {layers.map((layer, index) => (
            <Button
              key={index}
              variant="contained"
              fullWidth
              sx={{
                marginBottom: 1,
                color: "white",
                backgroundColor: layer.color,
                "&:hover": {
                  backgroundColor: layer.color,
                  opacity: 0.9,
                },
              }}
              onClick={() => {
                setSelectedLayer(layer);
                setShowDetailedInfo(false);
              }}
            >
              {index + 1}. {layer.name}
            </Button>
          ))}
        </div>
        <div style={{ width: "66%", paddingLeft: "16px" }}>
          {selectedLayer ? (
            <div>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  marginBottom: 1,
                  color: selectedLayer.color,
                }}
              >
                {selectedLayer.name} Layer
              </Typography>
              <Typography variant="body1">
                <strong>Explanation:</strong> {selectedLayer.explanation}
              </Typography>
              <Typography variant="body1">
                <strong>Analogy:</strong> {selectedLayer.analogy}
              </Typography>
              <Typography variant="body1">
                <strong>Examples:</strong> {selectedLayer.examples}
              </Typography>
              <Button
                onClick={() => setShowDetailedInfo(!showDetailedInfo)}
                sx={{ marginTop: 2 }}
                endIcon={<ArrowRightIcon />}
              >
                {showDetailedInfo ? "Hide Details" : "Learn More"}
              </Button>
              {showDetailedInfo && (
                <Card sx={{ marginTop: 2, backgroundColor: "#f5f5f5" }}>
                  <CardContent>
                    <Typography variant="h6">Detailed Information</Typography>
                    <Typography variant="body1">
                      {selectedLayer.detailedInfo.text}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Additional Examples:</strong>{" "}
                      {selectedLayer.detailedInfo.additionalExamples}
                    </Typography>
                    <div style={{ marginTop: "16px" }}>
                      <Typography variant="subtitle1">
                        Visual Representation
                      </Typography>
                      {selectedLayer.detailedInfo.visual}
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          ) : (
            <Typography variant="body1">
              Click on a layer to see more information.
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default OSIModel;
